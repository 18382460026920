import React from 'react'
import StyledHero from './styles'
import { StaticImage } from 'gatsby-plugin-image'
import { SocialList, RepoCard } from '../common'
import TypeIt from 'typeit-react'

import photos from './photo'
import PhotoAlbum from 'react-photo-album'

import { useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'

import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import { Skeleton } from '@mui/material'

const Galleryindex = () => {
  const [index, setIndex] = useState(-1)
  const [item, setItem] = useState(false)

  return (
    <>
      <div className="mt-3 w-full relative" style={{ height: '385px', width: '1024px' }}>
        <Skeleton
          variant="rect"
          className="rounded-md shadow-lg absolute top-0 left-0 w-full h-full"
          style={{ opacity: item ? 0 : 1 }}
        />
        <img
          className="rounded-md shadow-lg w-full h-full"
          src={'https://picsum.photos/800/300'}
          alt="My Image"
          onLoad={() => setItem(true)}
          style={{ opacity: item ? 1 : 0, transition: 'opacity 1s ease-in' }}
        />
      </div>
      <p className="text-center mt-3 text-xl text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-red-500">
        "Jeder Tag, an dem Du nicht tanzt, ist ein verlorener Tag des Lebens." - Friedrich Nietzsche
      </p>

      <div className="mt-10">
        <PhotoAlbum photos={photos} layout="rows" targetRowHeight={300} onClick={({ index }) => setIndex(index)} />

        <Lightbox
          slides={photos}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          // enable optional lightbox plugins
          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        />
      </div>
    </>
  )
}

export default Galleryindex
