import React from "react"
import { Layout } from "../components/layout"
import { Seo } from "../components/common"
import { Gallery } from "../components/gallery"

const BlogIndex = () => {
    return (
      <Layout>
        <Gallery />
      </Layout>
    )
  }
  
  export default BlogIndex
  
  export const Head = () => <Seo title="Gallery" />
  